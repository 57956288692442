// extracted by mini-css-extract-plugin
export var banner = "gdpr-banner-module--banner--04d05";
export var bannerVisible = "gdpr-banner-module--banner--visible--34021";
export var banner__button = "gdpr-banner-module--banner__button--55d25";
export var banner__text = "gdpr-banner-module--banner__text--b6da4";
export var mt0 = "gdpr-banner-module--mt-0--95c2b";
export var mt1 = "gdpr-banner-module--mt-1--df062";
export var mt2 = "gdpr-banner-module--mt-2--bd853";
export var mt3 = "gdpr-banner-module--mt-3--c756c";
export var mt4 = "gdpr-banner-module--mt-4--33957";
export var mt5 = "gdpr-banner-module--mt-5--10165";
export var mt6 = "gdpr-banner-module--mt-6--4b1fa";