// extracted by mini-css-extract-plugin
export var mt0 = "toast-module--mt-0--c92ee";
export var mt1 = "toast-module--mt-1--1fd23";
export var mt2 = "toast-module--mt-2--49ccc";
export var mt3 = "toast-module--mt-3--55869";
export var mt4 = "toast-module--mt-4--dbaae";
export var mt5 = "toast-module--mt-5--213c3";
export var mt6 = "toast-module--mt-6--5f196";
export var toasts = "toast-module--toasts--b5a34";
export var toasts__close = "toast-module--toasts__close--3da2f";
export var toasts__icon = "toast-module--toasts__icon--85a10";
export var toasts__item = "toast-module--toasts__item--ad002";
export var toasts__itemError = "toast-module--toasts__item--error--51896";
export var toasts__itemInfo = "toast-module--toasts__item--info--2e3de";
export var toasts__itemSuccess = "toast-module--toasts__item--success--55994";
export var toasts__itemVisible = "toast-module--toasts__item--visible--7d58a";