import {IGatsbyImageData} from "gatsby-plugin-image";

export type ContentfulButtonAppearance = "Solid" | "Outlined" | "Underlined";
export type HitObject = Record<string, unknown>;

export enum ToastTypes {
  SUCCESS = "success",
  ERROR = "danger",
  INFO = "info",
}

export enum ButtonAppearance {
  Filled = "filled",
  Outlined = "outlined",
  Text = "text",
  Unstyled = "unstyled",
}

export enum SearchResponse {
  NoQuery = 1,
  NoResults = 2,
  HasResults = 3,
}

export enum TOCLinkVisibility {
  SHOW = "Show",
  INDENTED = "Show (Indented)",
  HIDE = "Hide",
}

export interface BasicCard {
  body: {
    body: string;
  };
  ctaLabel: string;
  displayTitle: string;
  icon: BasicImage;
  page: {
    __typename: string;
    slug: string;
  };
  url: string;
  ariaLabel: string;
}

export interface BasicImage {
  description: string;
  gatsbyImageData: IGatsbyImageData;
  height: number;
  url: string;
  width: number;
}

export interface BasicLink {
  label: string;
  page: null | {
    __typename: string;
    slug: string;
  };
  url: string | null;
}

export interface BasicObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface PageMeta {
  pageTitle: string;
  pageDescription: string;
  ogImage: BasicImage;
  twitterImage: BasicImage;
}

export interface BasicHero {
  displayTitle: string;
  body: {
    body: string;
  };
  ctas: {
    appearance: ContentfulButtonAppearance;
    label: string;
    page: {
      __typename: string;
      slug: string;
    };
    url: string;
  }[];
  imageSize: "Half" | "Third";
  image: BasicImage;
}

export interface CardGroup {
  displayTitle: string;
  body: {
    body: string;
  };
  cards: BasicCard[];
}

export interface EDIDocument {
  assetTitle: string;
  displayTitle: string;
  userType: string;
  category: string;
  asset: {
    file: {
      url: string;
    };
  };
}

export interface GlobalNavigationSiteLogo {
  description: string;
  height: number;
  url: string;
  width: number;
}

export interface CTALink extends BasicLink {
  appearance: ContentfulButtonAppearance;
}

export interface ContentfulEDIDocumentationPage {
  pageMeta: PageMeta;
  title: string;
  slug: string;
  body: {
    body: string;
  };
  documents: EDIDocument[];
}

export interface ContentfulHomePage {
  contentfulHomePage: {
    cards: CardGroup;
    hero: BasicHero;
    pageMeta: PageMeta;
  };
}

export interface ContentfulNotFoundPage {
  contentfulNotFoundPage: {
    displayTitle: string;
    body: string;
    cta: BasicLink;
  };
}

export interface ContentfulTutorialPage {
  contentfulTutorialPage: {
    blocks: {
      displayTitle: string;
      tableOfContents: TOCLinkVisibility;
      body: {
        body: string;
      };
    }[];
    pageMeta: PageMeta;
    title: string;
  };
  contentfulSecondaryNavigation?: SideNavigation;
}

export interface ContentfulFaqPage {
  contentfulFaqPage: {
    blocks: {
      displayTitle: string;
      tableOfContents: TOCLinkVisibility;
      body: {
        body: string;
      };
    }[];
    pageMeta: PageMeta;
    title: string;
  };
  contentfulSecondaryNavigation?: SideNavigation;
}

export interface GlobalNavigation {
  siteLogo: GlobalNavigationSiteLogo;
  navigationItems: GlobalNavigationLink[];
  persistentNavigationItem: GlobalNavigationLink;
}

export interface GlobalNavigationLink {
  label: string;
  page: null | {
    __typename: string;
    slug: string;
  };
  url: string | null;
  active?: boolean;
  path?: string;
}

export interface EDIDocumentCollection {
  [key: string]: {
    [key: string]: EDIDocument[];
  };
}

export interface ResultObj {
  objectID: string;
  slug: string;
  pageTitle: string;
  pageType: string;
  blockTitle: string;
  body: string;
}

export interface Results {
  items: ResultObj[];
  pages: number;
  status: SearchResponse;
  total: number;
}

export interface SideNavigation {
  links: (SideNavigationLink | SideNavigationGroup)[];
}

export interface SideNavigationGroup {
  displayTitle: string;
  links: SideNavigationLink[];
}

export interface SideNavigationLink {
  displayTitle: string;
  page: {
    slug: string;
    __typename: string;
  };
}
