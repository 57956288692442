import React from "react";
import {Script} from "gatsby";
import {IS_NETLIFY_PREVIEW} from "/src/config";

const gtagAccountID = IS_NETLIFY_PREVIEW
  ? process.env.GATSBY_GTAG_ACCOUNT_ID_STAGING
  : process.env.GATSBY_GTAG_ACCOUNT_ID_PROD;

export default function TrackingTags() {
  return (
    <>
      <Script
        id="gtag-script"
        strategy="idle"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtagAccountID}`}
      />

      <Script id="gtag-init" strategy="idle">
        {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
           gtag('config', '${gtagAccountID}');
        `}
      </Script>
    </>
  );
}
