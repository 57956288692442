import {graphql, useStaticQuery} from "gatsby";

export const useGDPRBannerHook = () => {
  const {contentfulGdprBanner} = useStaticQuery(
    graphql`
      query {
        contentfulGdprBanner {
          allowConsentLabel
          disallowConsentLabel
          body
          cta {
            url
            label
            page {
              __typename
              ... on ContentfulTutorialPage {
                slug
              }
              ... on ContentfulEdiDocumentationPage {
                slug
              }
            }
          }
        }
      }
    `
  );

  return contentfulGdprBanner;
};
