import Cookie from "js-cookie";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import Button from "/src/components/button/Button";
import TrackingTags from "/src/components/tracking-tags/TrackingTags";

import {buildPageRoute, css} from "/src/utils";
import {COOKIE_CONSENT_NAME, IS_DEV_ENV} from "/src/config";
import {useGDPRBannerHook} from "/src/hooks/useGDPRBannerHook";

import * as styles from "./gdpr-banner.module.scss";
import {ButtonAppearance} from "/src/types";

interface ProviderProps {
  children: ReactNode;
}

interface BannerProps {
  onAccept: () => void;
  onReject: () => void;
}

interface Context {
  consent: boolean | undefined;
  setConsent: (value: boolean) => void;
}

const GDPRContext = createContext<Context>({
  consent: undefined,
  setConsent: () => undefined,
});

const {Provider} = GDPRContext;

function GDPRBanner({onAccept, onReject}: BannerProps) {
  const [visible, setVisible] = useState(false);

  const {allowConsentLabel, body, cta, disallowConsentLabel} =
    useGDPRBannerHook();

  useEffect(() => {
    setVisible(true);
  }, []);

  // use aria-live="polite" to bring the banner to the screen reader's attention
  // some SRs tend to read the first paragraphs twice:
  // https://www.w3.org/WAI/ARIA/apg/example-index/dialog-modal/dialog.html
  return (
    <div
      className={css(styles.banner, visible ? styles.bannerVisible : "")}
      role="dialog"
      aria-live="polite"
      aria-modal="false"
      aria-describedby="banner-body"
      id="gdpr-banner"
    >
      <div className={styles.banner__text}>
        <p id="banner-body">{body}</p>

        {cta && (
          <Button
            appearance={ButtonAppearance.Unstyled}
            classes="eyebrow"
            size="minimal"
            href={
              cta.url || buildPageRoute(cta.page?.slug, cta.page?.__typename)
            }
            target="_blank"
          >
            {cta.label}
          </Button>
        )}
      </div>

      <Button
        size="condensed"
        onClick={onReject}
        classes={styles.banner__button}
      >
        {disallowConsentLabel}
      </Button>

      <Button
        size="condensed"
        onClick={onAccept}
        classes={styles.banner__button}
      >
        {allowConsentLabel}
      </Button>
    </div>
  );
}

export function GDPRProvider({children}: ProviderProps) {
  const [visible, setVisible] = useState(false);
  const [consent, setConsent] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const cookieValue = Cookie.get(COOKIE_CONSENT_NAME);

    switch (cookieValue) {
      case undefined:
        setVisible(true);
        break;

      case "true":
        setConsent(true);
        break;

      case "false":
        setConsent(false);
        break;
    }
  }, []);

  const onAccept = () => {
    setConsent(true);
    setVisible(false);
    Cookie.set(COOKIE_CONSENT_NAME, "true");
  };

  const onReject = () => {
    setConsent(false);
    setVisible(false);
    Cookie.set(COOKIE_CONSENT_NAME, "false");
  };

  return (
    <Provider value={{consent, setConsent}}>
      {visible && <GDPRBanner onAccept={onAccept} onReject={onReject} />}
      {children}
      {IS_DEV_ENV === false && consent === true && <TrackingTags />}
    </Provider>
  );
}

export const useGDPR = () => {
  const {consent, setConsent} = useContext(GDPRContext);

  return {consent, setConsent};
};
