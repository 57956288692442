import sanitizeHtml from "sanitize-html";
import {SANITIZATION_OPTIONS, SANITIZATION_OPTIONS_BASIC} from "./config";

/**
 * Join a bunch of CSS classes into a single string.
 */
export function css(...args: string[]): string {
  return args
    .join(" ")
    .replace(/\s+/g, " ") // remove whitespace between classes
    .trim(); // remove whitespace at start and end of string
}

/**
 * Convert regular string into lowercase and kebab style.
 * Example: "Lorem ipsum 1234 foo!" => "lorem-ipsum-1234-foo"
 * Taken from:
 * https://gist.github.com/codeguy/6684588
 */
export function slugify(...args: (string | number)[]): string {
  return args
    .join(" ")
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .trim() // remove whitespace at start and end of string
    .replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, "-"); // separator
}

/**
 * Sanitize markup using sanitize-html.
 * Here we manually allow and disallow attributes on elements.
 */
export function sanitizeMarkdown(content: string, basic = false): string {
  const {allowedAttributes, allowedTags} =
    basic === true ? SANITIZATION_OPTIONS_BASIC : SANITIZATION_OPTIONS;

  return sanitizeHtml(content, {
    allowedTags,
    allowedAttributes,
  });
}

/**
 * Checks whether a URL points to another domain or a page within our domain.
 * As long as the string begins with '/', it'll be considered internal.
 * Example:
 * 1) /foo/bar => true
 * 2) https://flexport.com => false
 */
export function isInternalURL(url: string): boolean {
  return /^\/(?!\/)/.test(url);
}

/**
 * Convert a string to camel case.
 * Example: "FooBar" => "fooBar"
 */
export function toCamelCase(string: string): string {
  return `${string.charAt(0).toLowerCase()}${string.substring(1)}`;
}

/**
 * Convert a string to sentence case.
 * Example: "foobar" => "Foobar"
 */
export function toSentenceCase(string: string): string {
  return `${string.charAt(0).toUpperCase()}${string.substring(1)}`;
}

/**
 * Generates page route based on slug and Contentful type.
 * For example, a TutorialPage entry with the slug "foobar" should result in
 * "/tutorials/foobar/".
 */
export function buildPageRoute(slug?: string, typename?: string): string {
  if (typename === "ContentfulHomePage") {
    return "/";
  }

  if (slug === undefined || slug === null) {
    return "";
  }

  switch (typename) {
    case "tutorialPage":
    case "ContentfulTutorialPage":
      return `/tutorials/${slug}/`;

    case "faqPage":
    case "ContentfulFaqPage":
      return `/faq/${slug}/`;

    default:
      return `/${slug}/`;
  }
}

/**
 * Reinstate scrolling and remove right padding.
 */
export function restoreWindowScroll(): void {
  document.body.style.overflow = "";
  document.body.style.paddingRight = "";
}

/**
 * Hide the scrollbar and add right padding to avoid a visual "jump" in content.
 */
export function hideWindowScroll(): void {
  const scrollWidth = window.innerWidth - document.body.offsetWidth;

  document.body.style.overflow = "hidden";
  scrollWidth > 0 && (document.body.style.paddingRight = `${scrollWidth}px`);
}

/**
 * Check whether a nav link should be set to active based on the current route.
 * This is especially applicable to pages nested under a slug, like tutorials.
 * Example: if the current page is '/tutorial/foobar/', any nav item that has
 * '/tutorial/' as the first segment in its path should be set as active.
 */
export function isActiveNav(linkPath: string, currentPath?: string): boolean {
  if (currentPath === undefined) {
    return false;
  }

  const currentParent = currentPath.split("/")[1];
  const linkParent = linkPath.split("/")[1];

  return linkParent === currentParent ? true : false;
}

/**
 * After the drawer is shown:
 * 1) Hide the scrollbar.
 * 2) Add right padding to avoid a visual "jump" in content.
 * 3) Add `aria-hidden="true"` on selected elements to "trap" where the screen
 *    reader can navigate, like a focus trap.
 */
export function showDrawer(): void {
  const scrollWidth = window.innerWidth - document.body.offsetWidth;

  document.body.style.overflow = "hidden";
  scrollWidth > 0 && (document.body.style.paddingRight = `${scrollWidth}px`);

  [
    "main-header",
    "main-footer",
    "flexport-dev-main",
    "gdpr-banner",
    "portal-toast",
  ].forEach(id => {
    const $el = document.getElementById(id);

    $el && $el.setAttribute("aria-hidden", "true");
  });
}

/**
 * After the drawer is hidden:
 * 1) Reinstate scrolling.
 * 2) Remove right padding.
 * 3) Remove `aria-hidden="true"` from elements hidden from screen readers.
 */
export function hideDrawer(): void {
  document.body.style.overflow = "";
  document.body.style.paddingRight = "";

  [
    "main-header",
    "main-footer",
    "flexport-dev-main",
    "gdpr-banner",
    "portal-toast",
  ].forEach(id => {
    const $el = document.getElementById(id);

    $el && $el.removeAttribute("aria-hidden");
  });
}

/**
 * GA4 event tracking, specifically for clicks on the side nav and TOC items.
 */
export function gtagTrackClick(
  id: string,
  listName: string,
  itemName: string
): void {
  try {
    /* eslint-disable camelcase */
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    (window as any).gtag("event", "select_item", {
      item_list_id: id,
      item_list_name: listName,
      items: [{item_name: itemName}],
    });
    /* eslint-enable camelcase */
  } catch (e) {
    // do nothing.
  }
}

/**
 * Stylised console logging with flexport prepended in blue text background
 * for easier reading/scanning in logs.
 */
export function fpLog(...args: string[]): void {
  // eslint-disable-next-line no-console
  console.log("\x1b[44m%s\x1b[0m", "flexport", ...args);
}
