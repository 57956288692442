import React, {ReactNode} from "react";
import {GDPRProvider} from "./src/components/gdpr-banner/GDPRBanner";
import {ToastProvider} from "./src/components/toast/Toast";

export const wrapRootElement = ({element}: {element: ReactNode}) => {
  return (
    <GDPRProvider>
      <ToastProvider>{element}</ToastProvider>
    </GDPRProvider>
  );
};
