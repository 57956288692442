import {Renderer} from "marked";
import {highlight, languages} from "prismjs";
import {isInternalURL} from "./utils";

// netlify build's deploy context. can be "production", "deploy-preview" or "branch-deploy".
// if it's a preview env (including development), we'll also show Contentful entries in "Draft" status
export const IS_NETLIFY_PREVIEW = process.env.CONTEXT !== "production" || false;

export const IS_DEV_ENV =
  process.env.GATSBY_ACTIVE_ENV === "development" ||
  process.env.NODE_ENV === "development";

export const CODE_BLOCK_COPY_LABEL = "Copy";
export const CONTENT_TYPES_TO_INDEX = ["tutorialPage"];
export const CONTENTFUL_GET_ENTRIES_LIMIT = 1000;
export const COOKIE_CONSENT_NAME = "fp-dev-gdpr-consent";
export const PAGER_MAX_ITEMS = 7;
export const PAGER_PADDING = 2;
export const SEARCH_ITEMS_PAGE_PAGE = 10;
export const SEARCH_QUERY_MAX_LENGTH = 128;
export const SEARCH_QUERY_PLACEHOLDER = "What can we help you find?";
export const SEARCH_RESULT_MAX_CHARS = 256;
export const TOAST_LIFESPAN = 5000;
export const TOAST_MAX_ITEMS = 5;

export const ALGOLIA_INDEX = IS_NETLIFY_PREVIEW
  ? process.env.GATSBY_ALGOLIA_INDEX_DEV
  : process.env.GATSBY_ALGOLIA_INDEX_PROD;

/**
 * Number of "pages" before the button is shown.
 * Pages refers to how much of the viewport is scrolled, calculated using:
 * window.innerHeight x n
 */
export const BACK_TO_TOP_PAGES = 2;

/**
 * Options for marked.
 * https://marked.js.org/using_advanced#options
 */
export const MARKDOWN_OPTIONS = {
  highlight: (code: string, lang: string): string => {
    return highlight(code, languages[lang], lang);
  },
  breaks: true,
  gfm: true,
  sanitize: false,
};

/**
 * Override marked rendering methods.
 * https://marked.js.org/using_pro#renderer
 */
export const MARKDOWN_RENDERER = {
  br: () => "<br aria-hidden='true' />",
  code: (code: string, infostring: string, escaped: boolean): string => {
    return new Renderer().code(code, infostring || "plain", escaped);
  },
  heading: (text: string, level: number): string => {
    switch (level) {
      case 1:
      case 2:
      case 3:
        return `<h3 class="h${level}">${text}</h3>`;
      case 6:
        return `<h6 class="eyebrow">${text}</h6>`;
    }

    return `<h${level} class="h${level}">${text}</h${level}>`;
  },
  link: (href: string, title: string, text: string): string => {
    return isInternalURL(href)
      ? `<a href="${href}" title="${title}">${text}</a>`
      : `<a href="${href}" title="${title}" target="_blank" rel="noopener noreferrer">${text}</a>`;
  },
  paragraph: (text: string): string => `<p class="p">${text}</p>`,
  image: (src: string): string => `<img src=${src} loading="lazy" alt="" />`,
};

export const MARKDOWN_RENDERER_PLAIN = {
  blockquote: (quote: string): string => quote,
  br: (): string => " ",
  checkbox: (): string => "",
  code: (code: string): string => code,
  codespan: (code: string): string => code,
  del: (text: string): string => text,
  em: (text: string): string => text,
  heading: (text: string): string => text,
  hr: (): string => "",
  html: (html: string): string => html,
  image: (): string => "",
  link: (href: string, title: string, text: string): string => text,
  list: (body: string): string => body,
  listitem: (text: string): string => ` ${text} `,
  paragraph: (text: string): string => ` ${text} `,
  strong: (text: string): string => text,
  table: (header: string, body: string): string => ` ${header} ${body} `,
  tablecell: (content: string): string => `${content}`,
  tablerow: (content: string): string => `${content} `,
  text: (text: string): string => text,
};

/**
 * sanitize-html configuration.
 */
export const SANITIZATION_OPTIONS = {
  allowedTags: [
    "a",
    "blockquote",
    "br",
    "code",
    "del",
    "em",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "hr",
    "iframe",
    "img",
    "li",
    "ol",
    "p",
    "pre",
    "span",
    "strong",
    "table",
    "tbody",
    "td",
    "th",
    "thead",
    "tr",
    "ul",
  ],
  allowedAttributes: {
    "*": ["class", "target"],
    a: ["data-*", "href", "rel", "title"],
    br: ["aria-hidden"],
    iframe: ["src", "width", "height", "style"],
    img: ["src", "alt", "title", "loading"],
    pre: ["data-*"],
  },
};

export const SANITIZATION_OPTIONS_BASIC = {
  allowedTags: ["a", "br", "em", "hr", "p", "span", "strong"],
  allowedAttributes: {
    "*": ["class", "target"],
    a: ["data-*", "href", "rel", "title"],
    br: ["aria-hidden"],
  },
};
