import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {createPortal} from "react-dom";
import {v4 as uuid} from "uuid";

import Button from "/src/components/button/Button";
import Icon from "/src/components/icon/Icon";

import close from "/src/svg/close.svg";

import * as styles from "./toast.module.scss";

import {css, toSentenceCase} from "/src/utils";
import {ButtonAppearance, ToastTypes} from "/src/types";
import {TOAST_LIFESPAN, TOAST_MAX_ITEMS} from "/src/config";

interface ProviderProps {
  children: ReactNode;
}

interface ToastItem {
  id: string;
  message: string;
  type?: ToastTypes;
}

interface ToastProps {
  message: string;
  type?: ToastTypes;
  onClose: () => void;
}

interface Context {
  addToast: (message: string, type?: ToastTypes) => void;
}

const ToastContext = createContext<Context>({addToast: () => undefined});

const {Provider} = ToastContext;

function Toast({message, type = ToastTypes.INFO, onClose}: ToastProps) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    // set a little timeout to ensure a repaint and the transitions occur.
    setTimeout(() => setMounted(true), 10);
  }, []);

  const classes = css(
    "p-small",
    styles.toasts__item,
    mounted ? styles.toasts__itemVisible : "",
    styles[`toasts__item${toSentenceCase(type)}`]
  );

  return (
    <div className={classes} role="alert" aria-live="polite">
      <span>{message}</span>

      <Button
        appearance={ButtonAppearance.Unstyled}
        classes={styles.toasts__close}
        onClick={onClose}
        aria-label="Remove toast"
      >
        <Icon classes={styles.toasts__icon} svg={close} hidden />
      </Button>
    </div>
  );
}

export function ToastProvider({children}: ProviderProps) {
  const timers = useRef<{[key: string]: ReturnType<typeof setTimeout>}>({});

  const [mounted, setMounted] = useState(false);
  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const $portalToast = document.getElementById("portal-toast");

  useEffect(() => setMounted(true), []);

  const removeToast = (id: string): void => {
    setToasts(t => t.filter(toast => toast.id !== id));
    clearTimeout(timers.current[id]);
    delete timers.current[id];
  };

  const onAdd = (message: string, type?: ToastTypes): void => {
    const id = uuid();
    timers.current[id] = setTimeout(() => removeToast(id), TOAST_LIFESPAN);
    setToasts(t => {
      const items = [...t];
      return [...items.splice((TOAST_MAX_ITEMS - 1) * -1), {id, message, type}];
    });
  };

  return (
    <Provider value={{addToast: onAdd}}>
      {children}

      {mounted && $portalToast
        ? createPortal(
            <div className={styles.toasts}>
              {toasts.map(toast => (
                <Toast
                  key={toast.id}
                  message={toast.message}
                  type={toast?.type}
                  onClose={() => removeToast(toast.id)}
                />
              ))}
            </div>,
            $portalToast
          )
        : null}
    </Provider>
  );
}

export function useToast() {
  const {addToast} = useContext(ToastContext);

  return {addToast};
}
