import React, {forwardRef, ReactNode} from "react";
import {Link} from "gatsby";
import {css, isInternalURL} from "../../utils";
import * as styles from "./button.module.scss";
import {ButtonAppearance} from "/src/types";

interface ButtonProps {
  appearance?: ButtonAppearance;
  callback?: () => void;
  children: ReactNode;
  classes?: string;
  disabled?: boolean;
  href?: string;
  size?: "condensed" | "minimal";
  tabIndex?: number;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  [x: string]: any; // any other props
}

// test whether a URL is a page anchor. e.g. #jump-to-section
const isPageAnchor = (url: string): boolean => /^#/.test(url);

/**
 * TODO:
 * we need to expand on the ref type so that we can apply it to anchor links
 * as well; there's currently no straightforward way to achieve this.
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      appearance = ButtonAppearance.Filled,
      callback,
      children,
      classes = "",
      disabled,
      href,
      size,
      tabIndex,
      ...rest
    },
    ref
  ) => {
    const buttonStyles = css(
      appearance !== "unstyled" ? styles.button : "",
      appearance === "filled" ? styles.buttonFilled : "",
      appearance === "outlined" ? styles.buttonOutlined : "",
      appearance === "text" ? styles.buttonText : "",
      disabled === true ? styles.buttonDisabled : "",
      size === "condensed" ? styles.buttonCondensed : "",
      size === "minimal" ? styles.buttonMinimal : "",
      classes
    );

    tabIndex = disabled === true ? -1 : tabIndex;

    if (href) {
      return isInternalURL(href) ? (
        <Link
          aria-disabled={disabled ? "true" : undefined}
          className={buttonStyles}
          onClick={callback ? callback : undefined}
          tabIndex={tabIndex}
          to={href}
          {...rest}
        >
          {children}
        </Link>
      ) : (
        <a
          aria-disabled={disabled ? "true" : undefined}
          className={buttonStyles}
          href={href}
          onClick={callback ? callback : undefined}
          rel={isPageAnchor(href) ? undefined : "noopener noreferrer"}
          tabIndex={tabIndex}
          target={isPageAnchor(href) ? undefined : "_blank"}
          {...rest}
        >
          {children}
        </a>
      );
    }

    return (
      <button
        aria-disabled={disabled ? "true" : undefined}
        className={buttonStyles}
        disabled={disabled || undefined}
        onClick={callback ? callback : undefined}
        tabIndex={tabIndex}
        type="button"
        ref={ref}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";

export default Button;
