import React from "react";

interface Props {
  classes?: string;
  hidden?: boolean;
  svg: {
    url: string;
    viewBox: string;
  };
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  [x: string]: any; // any other props
}

export default function Icon({
  classes = "",
  hidden = false,
  svg,
  ...rest
}: Props) {
  return (
    <svg
      aria-hidden={hidden === true ? "true" : undefined}
      focusable={hidden === true ? "false" : undefined}
      className={classes}
      role="img"
      viewBox={svg.viewBox}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <use xlinkHref={svg.url} />
    </svg>
  );
}
